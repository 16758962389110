import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/ui-ux/1.png'
import icon2 from '../images/service-features-icons/ui-ux/2.png'
import icon3 from '../images/service-features-icons/ui-ux/3.png'
import icon4 from '../images/service-features-icons/ui-ux/4.png'
import featuredVideo from '../videos/pro4.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function UIUXDesigning() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'User Research',
      paragraph: 'Conducting in-depth research to understand your users and their needs.'
    },
    {
      icon: icon2,
      title: 'Prototyping',
      paragraph: 'Creating detailed wireframes and interactive prototypes to visualize the user journey.'
    },
    {
      icon: icon3,
      title: 'Visual Design',
      paragraph: 'Crafting aesthetically pleasing designs that align with your brand identity.'
    },
    {
      icon: icon4,
      title: 'Interaction Design',
      paragraph: 'Developing intuitive interactions that enhance user engagement.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Creating Intuitive and Engaging User Experiences' title='UI/UX <span class="highlight">Design</span>' paragraph='In today’s digital world, exceptional user experience is paramount. At Web Verse Solution, we specialize in crafting intuitive and visually stunning UI/UX designs that enhance user satisfaction and drive engagement. Our expert team is dedicated to creating user-centered designs that not only look beautiful but also function seamlessly across all devices.' />
      <ServiceFeatures tagline='User-Centric Design Philosophy' title='Comprehensive <span class="highlight">UI/UX</span><br /> Design Solutions' paragraph='We offer a full spectrum of UI/UX design services to meet all your needs.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Transformative UI/UX Designers' title='Bringing <span class="highlight">Ideas</span> to Life By <span class="highlight">Precision</span> & <span class="highlight">Creativity</span>' paragraph="Our team of experienced UI/UX designers is passionate about creating exceptional digital experiences. With a blend of creativity and technical expertise, we bring your ideas to life, ensuring every design element serves a purpose. Whether you’re building a new application or revamping an existing one, we have the skills and experience to deliver top-notch UI/UX designs that delight users and drive business success. We are proud of the positive impact our UI/UX designs have had on our clients' businesses. Exceptional user experiences start with great design. Contact us today to discuss your UI/UX design needs and discover how we can help you create digital products that are not only visually appealing but also highly functional and user-friendly." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default UIUXDesigning
