import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/social-media/1.png'
import icon2 from '../images/service-features-icons/social-media/2.png'
import icon3 from '../images/service-features-icons/social-media/3.png'
import icon4 from '../images/service-features-icons/social-media/4.png'
import icon5 from '../images/service-features-icons/social-media/5.png'
import featuredVideo from '../videos/pro8.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function SMM() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Content Creation',
      paragraph: 'Crafting engaging and shareable content that reflects your brand’s voice and message.'
    },
    {
      icon: icon2,
      title: 'Management',
      paragraph: 'Managing your social media accounts to ensure consistent and effective communication.'
    },
    {
      icon: icon3,
      title: 'Paid Advertising',
      paragraph: 'Creating and managing targeted ad campaigns to boost visibility and drive conversions.'
    },
    {
      icon: icon4,
      title: 'Analytics & Reporting',
      paragraph: 'Monitoring and analyzing performance to optimize strategies and demonstrate ROI.'
    },
    {
      icon: icon5,
      title: 'Community Engagement',
      paragraph: 'Building and nurturing relationships with your audience to foster loyalty and advocacy.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline="Boost Your Brand's Presence and Engagement" title='Social Media <br/> <span class="highlight">Marketing</span>' paragraph="In today's digital age, a strong social media presence is crucial for any business looking to connect with its audience and drive growth. At Web Verse Solution Stuidos, we specialize in creating and executing powerful social media marketing strategies that elevate your brand, engage your audience, and achieve your business objectives. From content creation to campaign management, we ensure your brand stands out in the crowded social media landscape." />
      <ServiceFeatures tagline='From Content Creation to Campaign Management' title='Comprehensive <span class="highlight">Social</span><br /> Media <span class="highlight">Marketing</span>' paragraph='We offer a full suite of social media marketing services designed to maximize your brand’s impact.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Strategic Social Media Planning' title='Crafting <span class="highlight">Strategies</span> That Deliver <span class="highlight">Results</span>' paragraph="Our approach to social media marketing is grounded in strategic planning and creative excellence. We begin by understanding your brand, your goals, and your target audience. Through comprehensive research and analysis, we develop a tailored social media strategy that aligns with your business objectives. Our focus is on creating meaningful and engaging content that resonates with your audience and drives measurable results. Our team of social media experts brings a wealth of experience and creativity to every project. We stay updated with the latest trends and best practices to ensure your social media strategy is both innovative and effective." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default SMM
