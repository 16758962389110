import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/shopify/1.png'
import icon2 from '../images/service-features-icons/shopify/2.png'
import icon3 from '../images/service-features-icons/shopify/3.png'
import icon4 from '../images/service-features-icons/shopify/4.png'
import featuredVideo from '../videos/pro7.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function ShopifyDevelopment() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Responsive Layout',
      paragraph: 'Ensuring your store looks and functions perfectly on all devices.'
    },
    {
      icon: icon2,
      title: 'App Integration',
      paragraph: 'Enhancing functionality with powerful Shopify apps and plugins.'
    },
    {
      icon: icon3,
      title: 'Payment Gateways',
      paragraph: 'Implementing secure and reliable payment options for your customers.'
    },
    {
      icon: icon4,
      title: 'SEO Optimization',
      paragraph: 'Ensuring your site is optimized for search engines to increase visibility.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Empowering Your Success with Custom Shopify Solutions' title='Shopify <span class="highlight">Development</span>' paragraph="In the fast-paced world of e-commerce, having a powerful and user-friendly online store is crucial for success. At Web Verse Solution, we specialize in Shopify development, providing custom solutions that transform your vision into a high-performing online store. Whether you're launching a new e-commerce business or enhancing an existing one, our expert team is here to help you leverage the full potential of Shopify to drive sales and growth." />
      <ServiceFeatures tagline='Feature-Rich Shopify Stores' title='Tailored <span class="highlight">Shopify</span><br /> Solutions' paragraph='We focus on developing Shopify stores that are not only visually appealing but also equipped with features that enhance functionality and user experience.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Tailored Shopify Solutions' title='Creating <Span class="highlight">Online Stores</span> That <span class="highlight">Reflect</span> Your <span class="highlight">Brand</span>' paragraph="Our approach to Shopify development is centered on understanding your unique business needs and goals. We start by analyzing your requirements and crafting a tailored strategy that aligns with your brand and market. From design and development to integration and optimization, we ensure your Shopify store is built to deliver a seamless shopping experience and drive conversions. In the fast-paced world of e-commerce, having a powerful and user-friendly online store is crucial for success. At Web Verse Solution we specialize in Shopify development, providing custom solutions that transform your vision into a high-performing online store. We stay updated with industry trends to ensure your store incorporates the most effective strategies" btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default ShopifyDevelopment
