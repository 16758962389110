import { Col, Row } from 'antd'
import React from 'react'
import ArrowRight from '../icons/ArrowRight'
import icon1 from '../images/expectation-icon-1.png'
import icon2 from '../images/expectation-icon-2.png'
import icon3 from '../images/expectation-icon-3.png'
import icon4 from '../images/expectation-icon-4.png'
import icon5 from '../images/expectation-icon-5.png'
import icon6 from '../images/expectation-icon-6.png'

const ExceedExpectations = () => {
    return (
        <section className='exceed-expectations'>
            <div className="container">
                <Row className='row-elem'>
                    <Col xl={10} xs={24}>
                        <h6 className="theme-tagline">Our Professionalism</h6>
                        <h2 className="theme-h2">We <span className="highlight">Exceed</span> Your <span className="highlight">Expectations</span></h2>
                    </Col>
                    <Col xl={10} xs={24}>
                        <p className="theme-p">At Web Verse Solution, we follow a structured and collaborative work process to ensure that every project is executed efficiently and delivers outstanding results. From the initial consultation to the final launch, we work closely with our clients every step of the way, keeping them informed and involved throughout the entire process.</p>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={22} xs={24}>
                        <div className="expectation-listing">
                            <Row className='listing-row' gutter={[{ xl: 30 }, { xl: 30 }]}>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon1} alt="" />
                                        </div>
                                        <h5 className="theme-h5">Expertise In Every Service</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon2} alt="" />
                                        </div>
                                        <h5 className="theme-h5">On Page SEO Excellence</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon3} alt="" />
                                        </div>
                                        <h5 className="theme-h5">Interactive Magic</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon4} alt="" />
                                        </div>
                                        <h5 className="theme-h5">Dedicated Project Manager</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon5} alt="" />
                                        </div>
                                        <h5 className="theme-h5">Comprehensive Testing</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                                <Col xl={8} xs={24}>
                                    <div className="expectaion-card">
                                        <div className="icon">
                                            <img src={icon6} alt="" />
                                        </div>
                                        <h5 className="theme-h5">Timely Delivery</h5>
                                        <p className="theme-p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <button className="theme-btn btn-outline"><ArrowRight /></button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ExceedExpectations
