import React, { useEffect, useState } from 'react'
import './App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import Portfolio from './pages/Portfolio'
import Header from './components/Header'
import Footer from './components/Footer'
import AboutUs from './pages/Abouts'
import ContactUs from './pages/Contact'
import ServicesAll from './pages/ServicesAll'
import BrandIdentity from './pages/Brandidentity'
import Logodesign from './pages/Logodesign'
import Uiuxdesign from './pages/Uiuxdesign'
import Wordpress from './pages/Wordpressdevelopment'
import Shopify from './pages/shopifydevelopment'
import Customsite from './pages/Customdevelopment'
import MobileApp from './pages/MobileApp'
import Socailmedia from './pages/Smm'
import Wixdevelopment from './pages/Wix'
import SqaureSpace from './pages/SqaureSpace'
import Seo from './pages/Seo'
import Contentwritting from './pages/Contentwritting'
import Animation from './pages/Videoanimation'



const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark')
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])
  useEffect(() => {

    if (theme === 'light') {
      document.documentElement.style.setProperty('--background-color', '#fff');
      document.documentElement.style.setProperty('--background-color-rgba', '255, 255, 255');
      document.documentElement.style.setProperty('--text-color', '#000');
      document.documentElement.style.setProperty('--text-color-rgba', '0, 0, 0');
      document.documentElement.style.setProperty('--invert-color', '#dfdfdf');
    } else {
      document.documentElement.style.setProperty('--background-color', '#1E1E1E');
      document.documentElement.style.setProperty('--background-color-rgba', '30, 30, 30');
      document.documentElement.style.setProperty('--text-color', '#fff');
      document.documentElement.style.setProperty('--text-color-rgba', '255, 255, 255');
      document.documentElement.style.setProperty('--invert-color', '#4c4c4c');
    }
  }, [theme])
  return (
    <div className={'theme-' + theme}>
      <Header theme={theme} setTheme={setTheme} />
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Home />} />
        <Route path='/Portfolio' element={<Portfolio />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/services' element={<ServicesAll />} />
        <Route path='/Brand-Identity' element={<BrandIdentity />} />
        <Route path='/Logodesign' element={<Logodesign />} />
        <Route path='/Uiuxdesign' element={<Uiuxdesign />} />
        <Route path='/Wordpress' element={<Wordpress />} />
        <Route path='/Shopfiydevelopment' element={<Shopify />} />
        <Route path='/Customsite' element={<Customsite />} />
        <Route path='/MobileApp' element={<MobileApp />} />
        <Route path='/Socailmedia' element={<Socailmedia />} />
        <Route path='/Wixdevelopment' element={<Wixdevelopment />} />
        <Route path='/Sqaurespace' element={<SqaureSpace />} />
        <Route path='/Seo' element={<Seo />} />
        <Route path='/Contentwritting' element={<Contentwritting />} />
        <Route path='/Animation' element={<Animation />} />
      </Routes>
      <Footer />
    </div>

  )
}

export default App
