import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/mobile-application/1.png'
import icon2 from '../images/service-features-icons/mobile-application/2.png'
import icon3 from '../images/service-features-icons/mobile-application/3.png'
import icon4 from '../images/service-features-icons/mobile-application/4.png'
import icon5 from '../images/service-features-icons/mobile-application/5.png'
import featuredVideo from '../videos/pro6.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function MobileApp() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Custom Design',
      paragraph: 'Tailored to your brand identity and user preferences.'
    },
    {
      icon: icon2,
      title: 'Cross-Platform',
      paragraph: 'Ensuring seamless performance on both iOS and Android devices.'
    },
    {
      icon: icon3,
      title: 'Intuitive Interface',
      paragraph: 'Simplifying navigation and enhancing user engagement.'
    },
    {
      icon: icon4,
      title: 'Robust Security',
      paragraph: 'Protecting user data with advanced security measures.'
    },
    {
      icon: icon5,
      title: 'Scalability',
      paragraph: 'Designed to grow with your business needs.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Transforming Ideas into Engaging Mobile Experiences' title='Mobile Application <span class="highlight">Development</span>' paragraph="In today’s mobile-first world, having a robust and intuitive mobile application is essential for reaching and engaging your audience. At Web Verse Solution, we specialize in creating custom mobile applications that not only meet your business needs but also provide a seamless user experience. Our expert team is dedicated to transforming your ideas into high-performance mobile apps that drive growth and enhance user engagement." />
      <ServiceFeatures tagline='From Concept to Launch' title='Comprehensive <span class="highlight">Mobile <br/> App</span> Solutions' paragraph='We focus on developing mobile applications that are not only visually appealing but also feature-rich and user-friendly.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Experienced Team of Developers' title='<span class="highlight">Leveraging</span> Cutting-Edge <span class="highlight">Technology</span>' paragraph="Our team of skilled developers is proficient in the latest technologies and frameworks, ensuring your mobile app is built to the highest standards. We stay updated with industry trends to incorporate innovative features and functionalities that keep your app ahead of the competition. With a strong focus on quality and performance, we deliver mobile applications that exceed expectations and drive business success. Our mobile app development process is designed to deliver end-to-end solutions, ensuring every aspect of your app is meticulously crafted. From initial concept and design to development, testing, and deployment, we work closely with you to create a mobile app that aligns with your vision and business goals." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default MobileApp
