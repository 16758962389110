import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/wordpress/1.png'
import icon2 from '../images/service-features-icons/wordpress/2.png'
import icon3 from '../images/service-features-icons/wordpress/3.png'
import icon4 from '../images/service-features-icons/wordpress/4.png'
import featuredVideo from '../videos/pro2.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function WordpressDevelopment() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Custom Development',
      paragraph: 'Designing unique and responsive themes tailored to your brand.'
    },
    {
      icon: icon2,
      title: 'Plugin Integration',
      paragraph: 'Enhancing functionality with the best WordPress plugins.'
    },
    {
      icon: icon3,
      title: 'E-commerce Solutions',
      paragraph: 'Building powerful online stores with WooCommerce.'
    },
    {
      icon: icon4,
      title: 'SEO Optimization',
      paragraph: 'Ensuring your site is optimized for search engines to increase visibility.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Custom WordPress Solutions for Your Unique Needs' title='WordPress <span class="highlight">Development</span>' paragraph='In today’s digital landscape, having a robust and user-friendly website is crucial for any business. At Web Verse Solution, we specialize in WordPress development, crafting custom solutions that not only meet your business needs but also provide an exceptional user experience. From small business websites to complex e-commerce platforms, our WordPress expertise ensures your site is scalable, secure, and easy to manage.' />
      <ServiceFeatures tagline='Customizable, Scalable, and Secure' title='Feature-Rich <br /><span class="highlight">WordPress</span> Websites' paragraph='We focus on developing WordPress websites that offer a rich set of features to enhance user experience and drive business growth.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Experienced WordPress Developers' title='Bringing Your <span class="highlight">Vision</span> To <span class="highlight">Life</span>' paragraph="Our team of experienced WordPress developers is skilled in the latest technologies and best practices. Whether you need a simple blog, a corporate website, or a complex e-commerce platform, we have the expertise to deliver high-quality WordPress solutions. We stay up-to-date with the latest trends and updates to ensure your website remains modern and competitive. Our WordPress development approach is centered around understanding your unique requirements and delivering solutions that align with your business goals. We start with an in-depth consultation to grasp your vision, followed by meticulous planning and execution. Our goal is to create a WordPress site that is not only visually appealing but also optimized." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default WordpressDevelopment
