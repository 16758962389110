import React from 'react'
import { Col, Row } from 'antd';
import Resolved from '../images/logo-white.png'
import { IoIosSend } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";






function Footer() {
    return (
        <div>
            <section className='footer-section0'>
                <div className="container">
                    <Row align="middle">
                        <Col span={24}>
                            <div className="footer-first-section">
                                <img src={Resolved} className='img-fluid' alt="img" />
                                <p>Welcome to Web Verse Solution, where boundless imagination meets limitless possibilities. Here, we invite you to embark on an inspiring journey of innovation and exploration. Join us as we push the boundaries of innovation, challenge the status quo, and dare to dream bigger. Together, let's craft a future where every idea has the power to shape our world.</p>
                            </div>
                        </Col>

                    </Row>
                    <Row className='mt-8' align="start">
                        <Col span={5}>
                            <div className="nav-links">
                                <h4>Main Links</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/">Services</a></li>
                                    <li><a href="/">Portfolio</a></li>
                                    <li><a href="/">Blogs</a></li>
                                    <li><a href="/">About</a></li>
                                    <li><a href="/">Contact Us</a></li>

                                </ul>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className="nav-links">
                                <h4>Services</h4>
                                <ul>
                                    <li><a href="/">Brand Identity</a></li>
                                    <li><a href="/">Logo Design</a></li>
                                    <li><a href="/">Ui/UX Design</a></li>
                                    <li><a href="/">Wordpress Development </a></li>
                                    <li><a href="/">Shopify Development</a></li>
                                    <li><a href="/">Custom  Website Development</a></li>
                                    <li><a href="/">Mobile Application Development </a></li>

                                </ul>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="nav-links">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><a href="/">FAQS</a></li>
                                    <li><a href="/">Testimonails</a></li>
                                    <li><a href="/">Privacy policy </a></li>
                                    <li><a href="/">Terms & Condittions</a></li>

                                </ul>
                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="newsletter">
                                <h4>Subscribe to our newsletter</h4>
                                <p>Subscribe to our newsletter to receive the latest updates, industry news, and expert insights from Web Verse Solution.</p>

                                <span>
                                    <input type="text" name="" id="" placeholder='Enter your email address' />
                                    <button type='submit' >
                                        <label > <IoIosSend /></label>
                                    </button>
                                </span>
                                <div className="social-media-icon">
                                    <ul>
                                        <li><a href="/"><FaFacebook /></a></li>
                                        <li><a href="/"><FaInstagram /></a></li>
                                        <li><a href="/"><BsTwitterX /></a></li>
                                        <li><a href="/"><FaLinkedin /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
                <div className="copy-write">
                    <h6>Copyright © 2024 Web Verse Solution | All Rights Reserved </h6>
                </div>
            </section>
        </div>
    )
}

export default Footer
