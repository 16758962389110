import React from 'react'
import futureCreativityVid from '../videos/future-of-creativity.mp4'
import topLeftCorner from '../images/corners/top-left.png'
import topRightCorner from '../images/corners/top-right.png'
import bottomRightCorner from '../images/corners/bottom-right.png'
import getToKnowBg from '../images/get-to-know-bg.png'
import getToKnowFeatured from '../images/get-know-featured.png'
import logoIcon from '../images/logo-icon.png'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'

const FutureCreativity = () => {
    return (
        <section className='future-creativity'>
            <div className="container">
                <div className="content-wrapper">
                    <video className='future-creativity-vid w-100' autoPlay muted loop>
                        <source src={futureCreativityVid} />
                    </video>
                    <div className="abs-content">
                        <Row>
                            <Col xl={18} className='title-wrapper'>
                                <div className="title-content">
                                    <h2 className="theme-h1 sec-title">The <span className='highlight'>Future</span> Of <span className='highlight-2'>Creativity</span> In The New Era Of <span className='highlight'>Now</span> !</h2>
                                    <img className='top-left-corner' src={topLeftCorner} alt="" />
                                </div>
                            </Col>
                            <Col xl={6} className='get-to-know'>
                                <img className='top-left-corner' src={topLeftCorner} alt="" />
                                <img className='bottom-right-corner' src={bottomRightCorner} alt="" />
                                <div className="get-to-know-card" style={{ backgroundImage: `url(${getToKnowBg})` }}>
                                    <h4 className="title-get-know">get to know us</h4>
                                    <p className="theme-p">At Web Verse Solution, we specialize in developing bespoke software solutions that streamline processes, boost ...</p>
                                    <img src={getToKnowFeatured} alt="" className="featured w-100" />
                                </div>
                            </Col>
                        </Row>
                        <Col className='flooded-wrapper' xl={11}>
                            <img className='logo-icon' src={logoIcon} alt="" />
                            <p className="theme-p">In a world flooded with the new digital era personaliztion is the key to capturing your audience’s attention and keeping them engaged. Discover how personalization is revolutionizing the way business connect with their customers.</p>
                        </Col>
                        <Col className='explore-wrapper' xl={11}>
                            <img className='top-right-corner' src={topRightCorner} alt="" />
                            <div className="text-content">
                                <p className="theme-p theme-1">Explore the cutting-edge tools technologies that make personalization in the new era of technology</p>
                                <Link to=''>
                                    <ArrowRight color='#000' />
                                </Link>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FutureCreativity
