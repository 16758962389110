import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/custom-development/1.png'
import icon2 from '../images/service-features-icons/custom-development/2.png'
import icon3 from '../images/service-features-icons/custom-development/3.png'
import icon4 from '../images/service-features-icons/custom-development/4.png'
import icon5 from '../images/service-features-icons/custom-development/5.png'
import featuredVideo from '../videos/pro5.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function CustomDevelopment() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Responsive Design',
      paragraph: 'Ensuring your website looks and functions beautifully on all devices.'
    },
    {
      icon: icon2,
      title: 'E-commerce',
      paragraph: 'Developing robust online stores with secure payment gateways.'
    },
    {
      icon: icon3,
      title: 'CMS',
      paragraph: 'Providing easy-to-use CMS for effortless content updates.'
    },
    {
      icon: icon4,
      title: 'SEO Optimization',
      paragraph: 'Incorporating best practices to improve your search engine ranking.'
    },
    {
      icon: icon5,
      title: 'Custom Functionality',
      paragraph: 'Developing unique features tailored to your specific needs.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Creating Unique Digital Experiences' title='Custom Website <span class="highlight">Development</span>' paragraph="In the digital age, your website is often the first impression potential customers have of your business. At Web Verse Solution, we specialize in creating custom websites that not only look stunning but also deliver exceptional user experiences. Our tailored approach ensures that your website reflects your brand identity, meets your specific needs, and engages your target audience effectively." />
      <ServiceFeatures tagline='From Concept to Launch' title='Tailored <span class="highlight">Web <br /> Development</span> Solutions' paragraph='We focus on developing websites that are not only visually appealing but also feature-rich and user-friendly.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Built for Performance and Usability' title='<span class="highlight">Features</span> That <span class="highlight">Set</span> Our <span class="highlight">Websites</span> Apart' paragraph="Our approach to custom website development is centered around your unique business goals and user needs. We start by understanding your vision and conducting thorough research to inform our design and development process. From initial concept and design to coding, testing, and launch, we ensure every aspect of your website is crafted to perfection, providing a seamless and engaging user experience. Our team of skilled web developers is proficient in the latest technologies and frameworks, ensuring your website is built to the highest standards. We stay updated with industry trends to incorporate innovative features and functionalities that keep your website ahead of the competition." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default CustomDevelopment
