import { Col, Row, Collapse } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'
import faqFeatured from '../images/faq-featured.png'
import ArrowDownIcon from '../icons/ArrowDown'

const FrequentlyAskedQuestions = () => {
    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
    const items = [
        {
            key: '1',
            label: <h6 className="question">01. What services does Web Verse Solution offer? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
        {
            key: '2',
            label: <h6 className="question">02. How do I get started with a project? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
        {
            key: '3',
            label: <h6 className="question">03. What is your typical project timeline? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
        {
            key: '4',
            label: <h6 className="question">04. How much do your services cost? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
        {
            key: '5',
            label: <h6 className="question">05. Can you help with e-commerce solutions? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
        {
            key: '6',
            label: <h6 className="question">06. Do you provide ongoing support and maintenance? <span className="icon"><ArrowDownIcon /></span></h6>,
            children: <p className='theme-p'>{text}</p>,
        },
    ];
    return (
        <section className='faq-sec'>
            <div className="container">
                <Row className='faq-row' gutter={[{ xl: 80 }, { xl: 80 }]}>
                    <Col xl={12} xs={24}>
                        <h6 className="theme-tagline">Frequently Asked Questions</h6>
                        <h2 className="theme-h2">Your <span className="highlight">Answers</span> To Common <span className="highlight">Queries</span></h2>
                        <p className="theme-p">At Web Verse Solution, we want you to have all the information you need to feel confident and informed. Our FAQ section provides answers to common questions about our services, processes, and policies. Browse our FAQs for quick and straightforward explanations, and feel free to reach out to us directly if you need any further assistance.</p>
                        <Link className='theme-btn btn-outline' to='/'>View More <ArrowRight /></Link>
                        <img className='img-fluid' src={faqFeatured} alt="" />
                    </Col>
                    <Col xl={12} xs={24}>
                        <Collapse accordion defaultActiveKey={['1']} ghost items={items} />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default FrequentlyAskedQuestions
