import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/wix-development/1.png'
import icon2 from '../images/service-features-icons/wix-development/2.png'
import icon3 from '../images/service-features-icons/wix-development/3.png'
import icon4 from '../images/service-features-icons/wix-development/4.png'
import icon5 from '../images/service-features-icons/wix-development/5.png'
import featuredVideo from '../videos/pro9.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function WixDevelopment() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Custom Design',
      paragraph: 'Tailored to your brand identity and user preferences.'
    },
    {
      icon: icon2,
      title: 'Responsive Layout',
      paragraph: 'Ensuring your store looks and functions perfectly on all devices.'
    },
    {
      icon: icon3,
      title: 'SEO Optimization',
      paragraph: 'Incorporating best practices to improve your search engine ranking.'
    },
    {
      icon: icon4,
      title: 'E-commerce',
      paragraph: 'Developing robust online stores with secure payment gateways.'
    },
    {
      icon: icon5,
      title: 'CMS',
      paragraph: 'Providing easy-to-use CMS for effortless content updates.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline="Crafting Stunning and Functional Websites with Wix" title='WIX <span class="highlight">Development</span>' paragraph="In today's digital age, having a professional and engaging online presence is crucial for your business's success. At Web Verse Solution, we specialize in developing beautiful, high-performance websites using Wix, one of the most versatile and user-friendly website building platforms. Whether you're a small business, a startup, or an established enterprise, our Wix development services will help you create a website that not only looks great but also drives results." />
      <ServiceFeatures tagline='From Concept to Launch' title='Tailored <span class="highlight">Wix</span> Website <br /> <span class="highlight">Solutions</span>' paragraph='Wix offers a range of powerful features that make it an excellent choice for building your website.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Powerful Features, Seamless Experience' title='<span class="highlight">Why</span> Choose <span class="highlight">Wix</span> for Your <span class="highlight">Website?</span>' paragraph="Our approach to Wix website development is comprehensive and client-focused. We start by understanding your business needs, goals, and target audience. Using this information, we design and develop a custom Wix website that aligns perfectly with your brand identity and business objectives. Our team ensures that every website we create is fully optimized for performance, usability, and scalability. Our team of skilled Wix developers is dedicated to delivering top-quality websites that exceed your expectations. We stay updated with the latest Wix features and best practices to ensure your website is modern, functional, and effective. From simple landing pages to complex e-commerce sites." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default WixDevelopment
