import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { RiArrowDropDownLine } from "react-icons/ri";
import { GoArrowUpRight } from "react-icons/go";
import { Dropdown } from 'antd';
import logo from '../images/logo.png'
import logoWhite from '../images/logo-white.png'
import $ from 'jquery'
import ArrowRight from '../icons/ArrowRight';

const Header = ({ theme, setTheme }) => {
    const handleTheme = (e) => {
        if (e.target.checked) {
            setTheme('light')
        } else {
            setTheme('dark')
        }
    }
    const location = useLocation()
    const [scroll, setScroll] = useState('main-header')
    $(window).scroll(function (e) {
        var scroll = $(window).scrollTop();
        if (scroll >= 100) {
            setScroll('main-header scroll')
        }
        else {
            setScroll('main-header')
        }
    })
    const links = [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            dropdown: [
                {
                    title: 'Brand Identity',
                    href: '/Brand-Identity'
                },
                {
                    title: 'Logo Design',
                    href: '/Logodesign'
                },
                {
                    title: 'UI/UX Design',
                    href: '/Uiuxdesign'
                },
                {
                    title: 'WordPress Development',
                    href: '/Wordpress'
                },
                {
                    title: 'Shopify Development',
                    href: '/shopfiydevelopment'
                },
                {
                    title: 'Custom Website Development',
                    href: '/Customsite'
                },
                {
                    title: 'Mobile Application Development',
                    href: '/mobileapp'
                },
                {
                    title: 'Social Media Marketing (SMM)',
                    href: '/socailmedia'
                },
                {
                    title: 'WIX Development',
                    href: '/Wixdevelopment'
                },
                {
                    title: 'Square Space Development',
                    href: '/SqaureSpace'
                },
                {
                    title: 'Seo (search engine optimization)',
                    href: '/Seo'
                },
                {
                    title: 'Video Animation',
                    href: '/videoanimtion'
                },
                {
                    title: 'content writting',
                    href: '/Contentwritting '
                },

            ]

        },
        {
            title: 'Portfolio',
            href: '/portfolio'
        },


        {
            title: `About Us`,
            href: '/aboutus'
        },
        {
            title: `Contact Us`,
            href: '/contactus'
        },
    ]
    return (
        <>
            <header className={scroll}>
                <div className="container">
                    <nav className='navbar'>
                        <Link className='nav-brand' to='/'>
                            <img className='img-fluid' src={theme === 'light' ? logo : logoWhite} alt="Web Verse Solution" />
                        </Link>
                        <ul className="navbar-nav">
                            {links.map((item, ind) => {
                                const items = item.dropdown?.map((drp, index) => ({
                                    key: index,
                                    label: <li className="dropdown-item">
                                        <Link className="nav-link drp-link" to={drp.href}>{drp.title} <GoArrowUpRight /></Link>
                                    </li>,
                                }))
                                return (
                                    <>
                                        {(item.dropdown && item.dropdown.length > 0) ? <>
                                            <li className="nav-item dropdown-wrapper" key={ind}>
                                                <Dropdown menu={{
                                                    items,
                                                }} placement="bottom" arrow>
                                                    <Link to='/services' className="nav-link dropdown-btn">{item.title} <RiArrowDropDownLine /></Link>
                                                </Dropdown>
                                                {/* {!item.href ?
                                                    <button className="nav-link dropdown-btn">{item.title} <RiArrowDropDownLine /></button> :
                                                    <Link className="nav-link dropdown-btn" to={item.href}>{item.title} <RiArrowDropDownLine /></Link>
                                                }
                                                <ul className="nav-dropdown">
                                                    {item.dropdown.map((drpItem, index) => {
                                                        return (
                                                            <li className="dropdown-item">
                                                                <Link className="nav-link" to={drpItem.href}>{drpItem.title} <GoArrowUpRight /></Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul> */}
                                            </li>
                                        </> : <>
                                            <li className="nav-item" key={ind}>
                                                <NavLink className={location.pathname === item.href ? "nav-link active" : "nav-link"} to={item.href}>{item.title}</NavLink>
                                            </li>
                                        </>
                                        }
                                    </>
                                )
                            })}
                        </ul>
                        <div className='header-end'>
                            <button className="theme-btn btn-outline">Get a Quote <ArrowRight /></button>
                            <div class="card__toggle">
                                <input id="themeSwitcher" onChange={handleTheme} class="ThemeToggle" type="checkbox" />
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header
